<template>
  <v-dialog justify="center" :value="value" persistent max-width="400">
    <v-card color=#fafafa>
      <section class="ma-0 pa-6">
        <v-row justify="center">
          <v-icon x-large color="red">mdi-trash-can-outline</v-icon>
        </v-row>
        <v-row justify="center">
            <span class='pa-4'>
            Delete "{{selectedAd.ad_name}}"?
            </span>
            <span class='pa-4'>
            Warning: This action cannot be undone.
            </span>
        </v-row>
        <v-row justify="center" class="pa-4">
          <v-btn height="40" class="elevation-1" @click.stop="$emit('update:value', false)">
            No
          </v-btn>
          <v-btn color="#0c67a5" height="40" class="white--text ml-2"
            @click="deleteAd(selectedAd, selectedAd.id)">Yes
          </v-btn>
        </v-row>
      </section>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'LayoutDelete',

  props: [
    'value',
    'selectedAd'
  ],

  methods: {
    async deleteAd (selectedAd, LayoutId) {
     selectedAd.status === 'Approved' ? this.$store.dispatch('setSnackbar', { status: 'error', text: `Cannot Delete an Approved Ad` }) :
      await this.$Layouts.deleteLayout(LayoutId).then(() => {
          this.$emit('update:value', false)
          this.$store.dispatch('setSnackbar', { status: 'success', text: `Deleted ${this.selectedAd.ad_name} Successfully` })
        }).catch (
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed on ${this.selectedAd.ad_name} Delete` })
        )
      }
      

    }
  
}
</script>